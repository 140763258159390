signin {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.signin {
	background-color: #212529;
	font-size: 1.3em;
}

.signin-container form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}		

footer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.contenedor {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	min-height: 100vh;
	background-color: #212529;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.cuerpo {
	width: 100%;
	box-sizing: border-box;
}

.pie {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #198754;
	padding-top: 1em;
	color: #FFF;
	box-sizing: border-box;
}

.newnav {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	position: sticky;
	z-index: 1;
	top: 0;
}

.newnav-one {
	width: 100%;
	padding-top: 1em;
	padding-right: 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.newnav-one-title {
	font-size: 1.2em;
}

.newnav-one-complement {
	display: flex;
	justify-content: center;
	align-items: center;
}

.newnav-one-complement-push {
	margin-right: .5em;
}

.newnav-two {
	padding-top: .5em;
	padding-left: 1em;
	padding-right: 1em;
	padding-bottom: .5em;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.newnav-two-nick {
	display: flex;
	justify-content: center;
	align-items: center;
}

.newnav-two-nick-text {
	color: #FFF;
	margin-right: .5em;
}

.newnav-three {
	font-size: 1.1em;	
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.newnav-three > li {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: .5em;
}

.filter-container {
	margin-top: 1em;
	margin-bottom: 1em;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
}

.filter-container-one {
	display: flex;
	justify-content: center;
	align-items: center;
}

.filter-container-two {
	margin-left: .5em;
	display: flex;
	justify-content: center;	
	align-items: center;
}

.invisibles {
	display: none;
}

.uploads-body {
	width: 100%;
	min-height: 100vh;
}
